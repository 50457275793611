import { type Dispatch, type SetStateAction, createContext, useState, useContext } from "react";
import { type AlertProps } from "~/types/alert";

interface AlertMessageData {
  alertMessage: AlertProps;
  setAlertMessage: Dispatch<SetStateAction<AlertProps>>;
}

export function isValidMessageStatus(status: string): status is AlertProps["type"] {
  return ["success", "error", "warning"].includes(status);
}

const AlertMessageContext = createContext<AlertMessageData | null>(null);

function AlertMessageProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const [alertMessage, setAlertMessage] = useState<AlertProps>({
    type: "success",
    message: "",
    title: "",
  });

  return (
    <AlertMessageContext.Provider
      value={{
        alertMessage: alertMessage,
        setAlertMessage: setAlertMessage,
      }}
    >
      {children}
    </AlertMessageContext.Provider>
  );
}

function useAlertMessage() {
  const context = useContext(AlertMessageContext);

  if (!context) {
    throw new Error("useAlertMessage has been used out of scope.");
  }

  return context;
}

export { AlertMessageProvider, useAlertMessage };
